@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.elem-btn {
  height: 3.5rem;
  padding: 0.5rem 1rem;
  font-family: "Manrope", sans-serif;
  border: none;
  border-radius: 3px;
  background: white;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 0 5px #222222;
  transition: all 0.3s ease-in-out;
  color: #222222; }
  .elem-btn:hover {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    box-shadow: 0 2px 10px #222222; }
  .elem-btn:focus {
    outline: none;
    box-shadow: 0 0 0 1px #717171, 0 0 0 3px rgba(255, 255, 255, 0.5); }
  .elem-btn--primary {
    color: white;
    background: #516cf0; }
  .elem-btn--secondary {
    color: white;
    background: #454754; }

.elem-inpt {
  height: 3.5rem;
  padding: 0.5rem 1rem;
  font-family: "Manrope", sans-serif;
  border: none;
  border-radius: 3px;
  background: #36393f;
  font-size: 1.5rem;
  line-height: 1.375rem;
  box-shadow: 0 0 5px #222222;
  transition: all 0.3s ease-in-out;
  color: white; }
  .elem-inpt:enabled::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .elem-inpt:enabled:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .elem-inpt:enabled::placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .elem-inpt:enabled:hover {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    box-shadow: 0 2px 10px #222222; }
  .elem-inpt:enabled:focus {
    outline: none;
    box-shadow: 0 0 0 1px #717171, 0 0 0 3px rgba(255, 255, 255, 0.5); }
  .elem-inpt:disabled::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.2); }
  .elem-inpt:disabled:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.2); }
  .elem-inpt:disabled::placeholder {
    color: rgba(255, 255, 255, 0.2); }

.loader {
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  -webkit-animation: scaleUpDown 2s infinite forwards;
          animation: scaleUpDown 2s infinite forwards;
  border: 10px solid #516cf0;
  background: transparent; }

.home-details {
  padding: 10rem 0; }
  .home-details__container__section {
    margin-bottom: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .home-details__container__section.odd {
      flex-direction: row-reverse; }
    .home-details__container__section__header {
      color: #36393f;
      font-size: 1.8rem;
      letter-spacing: 0.1rem;
      text-shadow: 0 0 0.1rem rgba(34, 34, 34, 0.4);
      line-height: 1.5;
      text-align: center;
      text-transform: capitalize;
      margin: 0 3rem;
      position: relative; }
    .home-details__container__section .random-art-1::after {
      content: "";
      position: absolute;
      bottom: 1rem;
      right: 2rem;
      width: 6rem;
      height: 5rem;
      z-index: -1;
      opacity: 0.3;
      border-radius: 3rem;
      background-color: #516cf0;
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
      -webkit-filter: hue-rotate(133deg);
              filter: hue-rotate(133deg); }
    .home-details__container__section .random-art-1::before {
      content: "";
      position: absolute;
      top: 0rem;
      left: 2rem;
      width: 12rem;
      height: 6rem;
      z-index: -1;
      opacity: 0.2;
      border-radius: 5rem;
      background-color: #f4812e;
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
      -webkit-filter: hue-rotate(17deg);
              filter: hue-rotate(17deg); }
    .home-details__container__section .random-art-2::after {
      content: "";
      position: absolute;
      bottom: 1rem;
      right: 0rem;
      width: 6rem;
      height: 4rem;
      z-index: -1;
      opacity: 0.3;
      border-radius: 3rem;
      background-color: #516cf0;
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
      -webkit-filter: hue-rotate(140deg);
              filter: hue-rotate(140deg); }
    .home-details__container__section .random-art-2::before {
      content: "";
      position: absolute;
      top: 0rem;
      left: 2rem;
      width: 9rem;
      height: 5rem;
      z-index: -1;
      opacity: 0.2;
      border-radius: 5rem;
      background-color: #f4812e;
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
      -webkit-filter: hue-rotate(46deg);
              filter: hue-rotate(46deg); }
    .home-details__container__section .random-art-3::after {
      content: "";
      position: absolute;
      bottom: 1rem;
      right: 3rem;
      width: 6rem;
      height: 4rem;
      z-index: -1;
      opacity: 0.3;
      border-radius: 3rem;
      background-color: #516cf0;
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
      -webkit-filter: hue-rotate(124deg);
              filter: hue-rotate(124deg); }
    .home-details__container__section .random-art-3::before {
      content: "";
      position: absolute;
      top: 0rem;
      left: 2rem;
      width: 7rem;
      height: 3rem;
      z-index: -1;
      opacity: 0.2;
      border-radius: 5rem;
      background-color: #f4812e;
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
      -webkit-filter: hue-rotate(110deg);
              filter: hue-rotate(110deg); }
    .home-details__container__section .random-art-4::after {
      content: "";
      position: absolute;
      bottom: 2rem;
      right: 0rem;
      width: 7rem;
      height: 5rem;
      z-index: -1;
      opacity: 0.3;
      border-radius: 3rem;
      background-color: #516cf0;
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
      -webkit-filter: hue-rotate(99deg);
              filter: hue-rotate(99deg); }
    .home-details__container__section .random-art-4::before {
      content: "";
      position: absolute;
      top: 0rem;
      left: 1rem;
      width: 8rem;
      height: 6rem;
      z-index: -1;
      opacity: 0.2;
      border-radius: 5rem;
      background-color: #f4812e;
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
      -webkit-filter: hue-rotate(98deg);
              filter: hue-rotate(98deg); }
    .home-details__container__section .random-art-5::after {
      content: "";
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      width: 5rem;
      height: 3rem;
      z-index: -1;
      opacity: 0.3;
      border-radius: 3rem;
      background-color: #516cf0;
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
      -webkit-filter: hue-rotate(90deg);
              filter: hue-rotate(90deg); }
    .home-details__container__section .random-art-5::before {
      content: "";
      position: absolute;
      top: 1rem;
      left: 2rem;
      width: 8rem;
      height: 4rem;
      z-index: -1;
      opacity: 0.2;
      border-radius: 5rem;
      background-color: #f4812e;
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
      -webkit-filter: hue-rotate(34deg);
              filter: hue-rotate(34deg); }
    .home-details__container__section .random-art-6::after {
      content: "";
      position: absolute;
      bottom: 2rem;
      right: 1rem;
      width: 6rem;
      height: 3rem;
      z-index: -1;
      opacity: 0.3;
      border-radius: 3rem;
      background-color: #516cf0;
      -webkit-transform: rotate(12deg);
              transform: rotate(12deg);
      -webkit-filter: hue-rotate(38deg);
              filter: hue-rotate(38deg); }
    .home-details__container__section .random-art-6::before {
      content: "";
      position: absolute;
      top: 1rem;
      left: 2rem;
      width: 10rem;
      height: 3rem;
      z-index: -1;
      opacity: 0.2;
      border-radius: 5rem;
      background-color: #f4812e;
      -webkit-transform: rotate(-12deg);
              transform: rotate(-12deg);
      -webkit-filter: hue-rotate(75deg);
              filter: hue-rotate(75deg); }
    .home-details__container__section__img {
      max-width: 70%;
      border: solid 0.3rem #516cf0;
      border-radius: 2rem;
      padding: 0.2rem;
      box-shadow: 0 0 0 1px #717171, 0 0 0 3px rgba(255, 255, 255, 0.5);
      transition: -webkit-filter 0.5s ease-in-out;
      transition: filter 0.5s ease-in-out;
      transition: filter 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
      will-change: filter; }
      .home-details__container__section__img:hover {
        -webkit-filter: hue-rotate(40deg);
                filter: hue-rotate(40deg); }

@media screen and (max-width: 860px) {
  .home-details {
    padding: 0; }
    .home-details__container__section {
      flex-direction: column; }
      .home-details__container__section.odd {
        flex-direction: column; }
      .home-details__container__section__header {
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 2rem; }
      .home-details__container__section__img {
        max-width: 90%; } }

.home-footer {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b2d36;
  box-shadow: 0 0 0.6rem rgba(34, 34, 34, 0.8);
  color: white; }
  .home-footer__right {
    padding: 2rem 4rem; }
    .home-footer__right__company, .home-footer__right__company__name {
      font-size: 1.4rem;
      color: white;
      letter-spacing: 0.285rem;
      font-weight: 500;
      line-height: 1.5; }
  .home-footer__left {
    padding: 2rem 4rem; }
    .home-footer__left__links {
      color: white;
      display: flex;
      font-size: 1.4rem;
      color: white;
      letter-spacing: 0.285rem;
      font-weight: 500;
      line-height: 1.5;
      flex-direction: column; }
      .home-footer__left__links__link {
        font-size: 1.2rem;
        color: white;
        letter-spacing: 0.062rem;
        line-height: 1.5;
        margin-top: 0.5rem; }
  .home-footer__flag {
    margin-bottom: 4rem;
    display: flex; }
    .home-footer__flag__saffron {
      width: 3rem;
      background-color: #ff9933;
      border-radius: 0.1rem;
      height: 0.6rem; }
    .home-footer__flag__white {
      border-radius: 0.1rem;
      width: 3rem;
      background-color: white;
      margin: 0 1rem;
      height: 0.6rem; }
    .home-footer__flag__green {
      width: 3rem;
      background-color: #138808;
      border-radius: 0.1rem;
      height: 0.6rem; }

@media screen and (max-width: 860px) {
  .home-footer__right {
    padding: 2rem; }
  .home-footer__left {
    padding: 2rem; } }

.home {
  overflow: hidden;
  position: absolute;
  height: 100vh;
  width: 100vw; }
  .home__content {
    position: absolute;
    height: 100%;
    overflow: hidden auto;
    width: 100%; }
    .home__content__nav {
      padding: 2rem 0;
      -webkit-backdrop-filter: blur(10rem);
              backdrop-filter: blur(10rem);
      box-shadow: 0 0 2rem rgba(34, 34, 34, 0.5);
      background-color: rgba(34, 34, 34, 0.5);
      font-size: 1.8rem;
      color: white;
      font-weight: 800;
      display: flex;
      align-items: center;
      width: 100%; }
      .home__content__nav__header {
        display: flex;
        align-items: center;
        padding: 0 15vw;
        justify-content: space-between;
        position: relative;
        width: 100%; }
        .home__content__nav__header__right {
          display: flex;
          flex-direction: row;
          align-items: center; }
        .home__content__nav__header__brand {
          letter-spacing: 0.032rem;
          font-size: 3rem;
          display: flex;
          font-weight: 300;
          align-items: center; }
          .home__content__nav__header__brand__logo {
            height: 3.4rem;
            margin-right: 1rem; }
        .home__content__nav__header__github {
          height: 3.5rem;
          width: 3.5rem;
          border-radius: 3.5rem; }
          .home__content__nav__header__github__img {
            height: 3rem;
            width: 3rem; }
        .home__content__nav__header__discord {
          height: 3.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.5rem;
          border-radius: 50%;
          margin-left: 1rem; }
          .home__content__nav__header__discord__img {
            width: 3rem; }
    .home__content__grad {
      width: 140vw;
      left: -20vw;
      top: -5rem;
      height: 65rem;
      position: absolute;
      border-radius: 35%;
      position: absolute;
      -webkit-transform: skewY(-3deg);
              transform: skewY(-3deg);
      z-index: -1;
      box-shadow: 0 0 0.6rem rgba(34, 34, 34, 0.8);
      background: linear-gradient(321.82deg, rgba(65, 129, 255, 0.1), rgba(68, 117, 255, 0.2) 45%, rgba(33, 242, 255, 0.4) 75%), #7196ff; }
    .home__content__wrapper {
      color: white;
      padding: 5rem 15vw;
      position: relative; }
      .home__content__wrapper .home-details {
        margin-top: 15rem; }
      .home__content__wrapper__hero-img {
        position: absolute;
        right: -20rem;
        top: 3rem;
        width: clamp(75rem, 70vw, 104rem);
        flex: 0 0.5 auto;
        object-fit: cover;
        object-position: 50% 50%; }
      .home__content__wrapper__hero {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-transform: capitalize; }
        .home__content__wrapper__hero__download-btn {
          padding: 1rem 3rem;
          background: #516cf0;
          font-size: 2.2rem;
          border-radius: 0.7rem;
          line-height: 1.5;
          letter-spacing: 0.032rem;
          font-weight: 600; }
        .home__content__wrapper__hero h1 {
          font-size: 3rem;
          width: 35rem;
          letter-spacing: 0.225rem;
          font-weight: 500;
          line-height: 1.5;
          text-shadow: 0 0 0.8rem rgba(34, 34, 34, 0.8); }
        .home__content__wrapper__hero .dividerdark {
          width: 14rem;
          height: 0.8rem;
          margin-top: 2rem;
          border-radius: 1rem;
          background-color: rgba(255, 255, 255, 0.6); }
        .home__content__wrapper__hero h2 {
          font-size: 1.6rem;
          font-weight: 400;
          width: 30rem;
          letter-spacing: 0.225rem;
          line-height: 1.5;
          margin: 3rem 0;
          line-height: 188%;
          text-shadow: 0 0 0.8rem rgba(34, 34, 34, 0.8); }
    .home__content__main {
      margin-top: 3rem;
      color: white;
      display: flex;
      flex-direction: column; }
      .home__content__main__img {
        width: 70vw;
        height: auto;
        margin-right: 2rem; }

@media screen and (min-width: 860px) and (max-width: 950px) {
  .home__content__wrapper__hero-img {
    display: none; } }

@media screen and (max-width: 860px) {
  .home__content__grad {
    height: 55rem; }
  .home__content__wrapper {
    padding: 2rem; }
    .home__content__wrapper__hero__download-btn {
      padding: 0.5rem 1rem;
      font-size: 1.3rem; }
    .home__content__wrapper__hero-img {
      display: none; }
  .home__content__nav {
    font-size: 1.5rem;
    padding: 1.5rem 0; }
    .home__content__nav__header {
      padding: 0 2rem; }
      .home__content__nav__header__brand {
        font-size: 2rem; }
        .home__content__nav__header__brand__logo {
          height: 2rem; }
      .home__content__nav__header__github {
        height: 2.5rem;
        width: 2.5rem; }
        .home__content__nav__header__github__img {
          height: 2.5rem;
          width: 2.5rem; }
      .home__content__nav__header__discord {
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        border-radius: 50%;
        margin-left: 1rem; }
        .home__content__nav__header__discord__img {
          width: 2.5rem; } }

.release-notes {
  height: 100%;
  overflow: hidden; }
  .release-notes__header {
    font-size: 3rem;
    letter-spacing: 0.5rem;
    font-weight: 600; }
  .release-notes__body {
    overflow-y: scroll;
    height: 90%; }
    .release-notes__body .markdown-body {
      color: white; }

.download-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute; }
  .download-container::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.8);
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0; }
  .download-container__bg-img {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: -1;
    -webkit-animation: scaleDown 2.5s forwards;
            animation: scaleDown 2.5s forwards; }
  .download-container__wrapper {
    height: 50rem;
    width: 90rem;
    position: absolute;
    -webkit-animation: slideDown 1.5s forwards;
            animation: slideDown 1.5s forwards;
    background: rgba(34, 34, 34, 0.8);
    box-shadow: 0 0 5px #222222;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    color: white;
    z-index: 1; }
    .download-container__wrapper .download-box {
      height: 100%;
      width: 45%;
      display: flex;
      justify-content: center;
      background: #2b2d36;
      transition: margin 0.4s ease-in-out; }
      .download-container__wrapper .download-box__content {
        width: 100%;
        padding: 2rem 5rem; }
        .download-container__wrapper .download-box__content__header {
          font-size: 5rem;
          text-align: center; }
        .download-container__wrapper .download-box__content__input-section {
          margin-top: 4rem;
          display: flex;
          flex-direction: column;
          align-items: center; }
          .download-container__wrapper .download-box__content__input-section button {
            margin-top: 2rem;
            width: 25rem;
            height: 5rem;
            letter-spacing: 0.2rem; }
          .download-container__wrapper .download-box__content__input-section__subtext {
            margin-top: 0.6rem;
            font-size: 1.2rem; }
    .download-container__wrapper .changelog {
      padding: 1rem 0 0 3rem;
      position: relative;
      height: 100%;
      width: 55%;
      transition: margin 0.4s ease-in-out;
      overflow: auto; }

.inline-icon {
  width: 1.17em;
  display: inline;
  vertical-align: bottom;
  color: white; }

@media screen and (max-width: 948px) {
  .download-container__wrapper {
    margin: 2rem 0;
    padding-top: 2rem;
    width: 80%;
    height: 90%;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .download-container__wrapper .download-box {
      height: 60%;
      width: 60%;
      text-align: center;
      padding: 0 12.5%; }
      .download-container__wrapper .download-box__content {
        width: 100%;
        padding: 2rem 2rem; }
        .download-container__wrapper .download-box__content__header {
          font-size: 3rem;
          text-align: center; }
        .download-container__wrapper .download-box__content__input-section button {
          margin-top: 1rem;
          width: 20rem; }
  .download-container .changelog {
    width: 80%; } }

@-webkit-keyframes scaleUp {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }
@keyframes scaleUp {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.animation-scale-up {
  -webkit-animation: scaleUp 0.5s forwards;
          animation: scaleUp 0.5s forwards; }

@-webkit-keyframes scaleDown {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@keyframes scaleDown {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.animation-scale-down {
  -webkit-animation: scaleDown 0.5s forwards;
          animation: scaleDown 0.5s forwards; }

@-webkit-keyframes slideDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5%);
            transform: translateY(-5%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.animation-slide-down {
  -webkit-animation: slideDown 0.5s forwards;
          animation: slideDown 0.5s forwards; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.animation-fade-out {
  -webkit-animation: fadeOut 0.5s forwards;
          animation: fadeOut 0.5s forwards; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.animation-fade-in {
  -webkit-animation: fadeIn 0.5s forwards;
          animation: fadeIn 0.5s forwards; }

@-webkit-keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5%);
            transform: translateY(5%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5%);
            transform: translateY(5%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.animation-slide-up {
  -webkit-animation: slideUp 0.5s forwards;
          animation: slideUp 0.5s forwards; }

@-webkit-keyframes blurDown {
  0% {
    -webkit-filter: blur(15px);
            filter: blur(15px); }
  100% {
    -webkit-filter: blur(0);
            filter: blur(0); } }

@keyframes blurDown {
  0% {
    -webkit-filter: blur(15px);
            filter: blur(15px); }
  100% {
    -webkit-filter: blur(0);
            filter: blur(0); } }

@-webkit-keyframes blurUpDown {
  0% {
    -webkit-filter: blur(0);
            filter: blur(0); }
  50% {
    -webkit-filter: blur(15px);
            filter: blur(15px); }
  100% {
    -webkit-filter: blur(0);
            filter: blur(0); } }

@keyframes blurUpDown {
  0% {
    -webkit-filter: blur(0);
            filter: blur(0); }
  50% {
    -webkit-filter: blur(15px);
            filter: blur(15px); }
  100% {
    -webkit-filter: blur(0);
            filter: blur(0); } }

@-webkit-keyframes scaleUpDown {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@keyframes scaleUpDown {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

.scale-down-appear {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.scale-down-appear-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 600ms ease-out; }

.scale-down-enter {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.scale-down-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 300ms ease-out 300ms; }

.scale-down-exit {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.scale-down-exit-active {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 300ms ease-out; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Manrope", sans-serif; }

html {
  font-size: 62.5% !important;
  background: #e5f1fa; }

body {
  font-size: 1.4rem; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -webkit-touch-callout: none;
  /* iOS Safari */
  /* Konqueror HTML */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  /* Old versions of Firefox */
  user-select: none;
  /* Non-prefixed version*/ }

.typo-align-center {
  text-align: center; }

