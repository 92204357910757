*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Manrope", sans-serif;
}

html {
  font-size: 62.5% !important; // 1rem = 10px
  background: $color-ice-white;
}

body {
  font-size: 1.4rem;
}

.noselect {
  -webkit-user-select: none;    /* Safari */
  -webkit-touch-callout: none;  /* iOS Safari */
  -khtml-user-select: none;     /* Konqueror HTML */
  -ms-user-select: none;        /* Internet Explorer/Edge */
  -moz-user-select: none;       /* Old versions of Firefox */
   user-select: none;           /* Non-prefixed version*/
}
