@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
$font-family: "Manrope", sans-serif;

// COLORS
$color-white: hsla(230, 7%, 100%, 1);
$color-white-op-80: hsla(230, 7%, 100%, 0.8);
$color-white-op-60: hsla(230, 7%, 100%, 0.6);
$color-white-op-50: rgba(255, 255, 255, 0.5);
$color-white-op-20: hsla(230, 7%, 100%, 0.2);
$color-ice-white: rgba(229, 241, 250, 1);

$color-black: #222222;
$color-black-op-40: rgba(34, 34, 34, 0.4);
$color-black-op-50: rgba(34, 34, 34, 0.5);
$color-black-op-80: rgba(34, 34, 34, 0.8);

$color-primary: hsla(230, 84%, 63%, 1);
$color-secondary: hsla(230, 10%, 30%, 1);

$color-grey: #36393f;
$color-grey-dark: hsla(230, 11%, 19%, 1);
$color-grey-light: #717171;

$color-purple: rgba(88, 45, 210, 1);
$color-purple-op-80: rgba(88, 45, 210, 0.8);
$color-purple-op-50: rgba(88, 45, 210, 0.5);

$color-magenta: rgba(207, 45, 118, 1);
$color-magenta-op-80: rgba(207, 45, 118, 0.8);
$color-magenta-op-50: rgba(207, 45, 118, 0.5);

$color-orange: rgba(244, 129, 46, 1);
$color-orange-op-80: rgba(244, 129, 46, 0.8);
$color-orange-op-50: rgba(244, 129, 46, 0.5);

$btn-focus-shadow: 0 0 0 1px $color-grey-light, 0 0 0 3px $color-white-op-50;
$inpt-focus-shadow: 0 0 0 1px $color-grey-light, 0 0 0 3px $color-white-op-50;
