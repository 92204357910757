@mixin random-bef-aft {
  &::after {
    content: "";
    position: absolute;
    bottom: #{randomNum(0, 3)}rem;
    right: #{randomNum(0, 3)}rem;
    width: #{randomNum(3, 7)}rem;
    height: #{randomNum(3, 6)}rem;
    z-index: -1;
    opacity: 0.3;
    border-radius: 3rem;
    background-color: $color-primary;
    transform: rotate(12deg);
    filter: hue-rotate(#{randomNum(10, 150)}deg);
  }

  &::before {
    content: "";
    position: absolute;
    top: #{randomNum(0, 2)}rem;
    left: #{randomNum(0, 2)}rem;
    width: #{randomNum(6, 12)}rem;
    height: #{randomNum(3, 6)}rem;
    z-index: -1;
    opacity: 0.2;
    border-radius: 5rem;
    background-color: $color-orange;
    transform: rotate(-12deg);
    filter: hue-rotate(#{randomNum(10, 150)}deg);
  }
}
